import React, { useContext } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
// import Container from '../components/Container'
import { Box, Flex } from '@chakra-ui/react'
import { H1, H4 } from '../theme/Headings'
import { Button } from '../theme/Buttons'
import ColorContext from '../context/ColorContext'
import { Helmet } from "react-helmet"

const IndexPage = ({ data, location }) => {
  const { color } = useContext(ColorContext)

  // Open Weather Map Daylight Hours
  const daylight = (((data.openWeather.sys.sunset - data.openWeather.sys.sunrise) / 60) / 60)
  const hrsOfDaylight = Math.floor(daylight)
  const fraction = daylight - hrsOfDaylight
  const minOfDaylight = Math.floor(fraction * 60)
  const schema = [
{
  "@context": "https://schema.org",
  "@type": "LocalBusiness",
  "name": "AlopexID",
  "image": "https://alopexid.com/images/alopex-logo.png",
  "@id": "",
  "url": "https://alopexid.com/",
  "telephone": "9075317475",
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "564 S Denali St",
    "addressLocality": "Palmer",
    "addressRegion": "AK",
    "postalCode": "99645",
    "addressCountry": "US"
  },
  "geo": {
    "@type": "GeoCoordinates",
    "latitude": 61.6006466,
    "longitude": -149.1083226
  },
  "openingHoursSpecification": {
    "@type": "OpeningHoursSpecification",
    "dayOfWeek": [
      "Monday",
      "Tuesday",
      "Thursday",
      "Friday",
      "Wednesday"
    ],
    "opens": "09:00",
    "closes": "17:00"
  },
  "sameAs": [
    "https://www.facebook.com/alopexid/",
    "https://www.instagram.com/alopexid/?hl=en",
    "https://www.pinterest.com/alopexid/",
    "https://www.linkedin.com/company/alopex-interaction-design/"
  ] 
}
]
  return (
    <Layout>
      <SEO schemaMarkUp={schema} noIndex/>
      <iframe 
        src="/3dhome/alopex_home_page.html"
        allow="autoplay; fullscreen; transparency"
        allowtransparency="true"
        width="100%"
        height="100%"
        title="3D fox island wow"
        className="iframeThang"
        style={{
          position: `relative`,
          border: `none`,
          width: `100%`,
          height: `100vh`,
          top: 0,
          left:0,
          backgroundColor: '#37474f',
          backgroundImage: `linear-gradient(135deg, ${color.alpha}, ${color.bravo})`
        }} 
      />
      <Box className="overIframe">
        <H1>We are Alopex.</H1>
        <Box as="p" maxWidth="370px">We design and build the best marketing experiences from our office in Palmer, Alaska.</Box>
        <div className="sitRep">
          <p>
            {`${Math.round(data.openWeather.main.temp)}°F`}
          </p>
          <p>
            {hrsOfDaylight}:{minOfDaylight < 10 ? `0${minOfDaylight}` : minOfDaylight} hours of daylight today
          </p>
        </div>
      </Box>
      <Box id="preloader" className="preloader">
        <svg id="loader" className="loader" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 80">
          <path style={{fill:`#eee`}} d="M7.67,38.4,0,.5,29.91,29.3,60,0,52.52,38.4,29.91,80Zm23,37.2L50.73,38.5,30.66,31.4Zm-1.42,0V31.4L9.37,38.5ZM51.29,37.1,57.73,4.3,31.32,30ZM8.9,37.1,28.58,30,2.37,4.8Z" />
        </svg>
        <Box className="progress_container">
          <Box className="progress_bar" />
        </Box>
      </Box>
      <Box className="homeCTABox" display="none" bottom={['20px', null, null, '50px']} left={['5%', null, null, '10%']}>
        <H4 mb="20px">What can we help you with today?</H4>
        <Flex display="flex" flexDirection="row" justifyContent={['center', null, 'flex-start']} width="100%">
          <Link className="buttonHome" to="/contact">
            <Button
              variant="buttonHome"
              style={{color: color.alpha, height: 'auto'}}
            >
              SEO, I need to be seen
            </Button>
          </Link>
          <Link className="buttonHome" to="/contact">
            <Button
              variant="buttonHome"
              style={{color: color.alpha, height: 'auto'}}
            >
              Get my brand on point
            </Button>
          </Link>
          <Link className="buttonHome" to="/contact">
            <Button
              variant="buttonHome"
              style={{color: color.alpha, height: 'auto'}}
            >
              I need a website
            </Button>
          </Link>
          <Link className="buttonHome buttonHomeMobile" to="/contact">
            <Button
              variant="buttonHome"
              style={{color: color.alpha, height: 'auto'}}
            >
              SEO
            </Button>
          </Link>
          <Link className="buttonHome buttonHomeMobile" to="/contact">
            <Button
              variant="buttonHome"
              style={{color: color.alpha, height: 'auto'}}
            >
              Brand
            </Button>
          </Link>
          <Link className="buttonHome buttonHomeMobile" to="/contact">
            <Button
              variant="buttonHome"
              style={{color: color.alpha, height: 'auto'}}
            >
              Website
            </Button>
          </Link>
        </Flex>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query aloIndex {
    site {
      siteMetadata {
        title
      }
    }
    openWeather {
      main {
        temp
      }
      sys {
        sunrise
        sunset
      }
    }
  }
`

export default IndexPage
